<template>
  <div class="row">
    <div class="col-lg-6">
      <b-card
        class="card-custom gutter-b"
        no-body
      >
        <!--begin:Header-->
        <div class="card-header flex-wrap border-0 pt-2 pb-0">
          <b-card-title>
            <h2 class="card-label">
              {{ trans('dashboard.cards.stats.title') }}
            </h2>
          </b-card-title>
        </div>
        <!--end:Header-->
        <!--begin:Body-->
        <div class="card-body">
          <template
            v-for="(item, i) in list"
          >
            <!--begin::item-->
            <!-- eslint-disable-next-line vue/no-v-for-template-key-on-child -->
            <div class="d-flex align-items-center mb-10" :key="i">
              <!--begin::Symbol-->
              <div
                class="symbol symbol-40 mr-5"
                :class="`symbol-light-${item.style}`"
              >
                <span class="symbol-label">
                  <span
                    class="svg-icon svg-icon-lg"
                    :class="`svg-icon-${item.style}`"
                  >
                    <inline-svg :src="item.svg"></inline-svg>
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <div class="d-flex flex-column">
                <span
                  class="text-dark-50 text-hover-primary mb-1 font-size-lg"
                >
                  {{ item.title }}
                </span>
                <span
                  class="font-size-lg font-weight-bold"
                >
                  {{ item.desc }}
                </span>
              </div>
            </div>
            <!--end::item-->
          </template>
        </div>
        <!--end:Body-->
      </b-card>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from '@vue/composition-api'

  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

  import { capitalize } from '@src/core/helpers/textUtils'
  import { trans, transChoice } from '@src/core/helpers/translate'

  const fb = require('@src/firebaseConfig')

  export default {
    name: 'Dashboard',
    setup () {
      const counts = ref({
        templates: 0,
        products: 0,
        customers: 0,
        extUsers: 0,
        users: 0
      })

      const init = async () => {
        const getCounts = fb.functions.httpsCallable('backendGetCounts')
        await getCounts()
          .then((res) => {
            const { data } = res
            if (Object.keys(data).length > 0) {
              Object.keys(counts.value).forEach((key) => {
                if (data[key] !== undefined) counts.value[key] = data[key]
              })
            }
          }).catch((err) => {
            console.log('ERRORS:', err)
          })
      }

      const list = computed(() => [
        {
          title: capitalize(transChoice('global.template', 2)),
          desc: counts.value.templates,
          svg: '/media/svg/icons/Design/Layers.svg',
          style: 'primary'
        },
        {
          title: capitalize(transChoice('global.product', 2)),
          desc: counts.value.products,
          svg: '/media/svg/icons/Shopping/Box1.svg',
          style: 'warning'
        },
        {
          title: capitalize(transChoice('global.customer', 2)),
          desc: counts.value.customers,
          svg: '/media/svg/icons/Files/User-folder.svg',
          style: 'success'
        },
        {
          title: capitalize(transChoice('global.session', 2)),
          desc: 'coming soon...',
          svg: '/media/svg/icons/Communication/Clipboard-check.svg',
          style: 'info'
        },
        {
          title: capitalize(trans('dashboard.cards.stats.list.backendUsers')),
          desc: counts.value.users,
          svg: '/media/svg/icons/General/User.svg',
          style: 'info'
        }
      ])

      init()

      return {
        list,
        trans,
        transChoice
      }
    },
    mounted () {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: 'Dashboard'
        }
      ])
    },
    beforeMount () {
      this.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'Dashboard')
    }
  }
</script>
